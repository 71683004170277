<template>
	<div class="flex flex-row flex-grow" v-if="authUser">
		<Menu :isOgv="true" :isMinzdravOgv="isMinzdravOgv">
			<template v-slot:li>
				<template v-for="(item, key) in menuList">
					<li :key="'A' + key" v-if="item.access">
						<div class="flex flex-row mr-auto mb-6 ml-5 items-center text-sm cursor-pointer" :class="{ active: tabIsActive(item, menu) }" @click.prevent="goRoute(item)">
							<div class="flex justify-center items-center h-8 min-h-8 w-8 min-w-8">
								<Icons :icon-name="item.icon" :title="!menu ? item.name : ''" />
							</div>
							<p class="flex p-2" v-if="menu">{{ item.name }}</p>
						</div>
						<ul v-if="item.subItems && menu">
							<template v-for="(subitem, subKey) in item.subItems">
								<li
									:key="'sub' + subKey"
									class="flex pl-8 flex-row h-14 mr-auto mb-6 ml-5 items-center text-sm cursor-pointer"
									:class="{ active: $route.path == subitem.router }"
									@click.prevent="goRoute(subitem)"
									v-if="subitem.access"
								>
									<p class="flex p-2 relative" v-if="menu">{{ subitem.name }}</p>
								</li>
							</template>
						</ul>
					</li>
				</template>
			</template>
		</Menu>
		<div class="flex flex-col flex-grow clear-box" :class="{ open: menu }">
			<Header :isMinzdravOgv="isMinzdravOgv"></Header>
			<router-view />
		</div>
	</div>

	<router-view v-else />
</template>

<script>
import Menu from '../../components/Menu.vue';
import Icons from '../../components/Icons.vue';
import Header from '../../components/Header.vue';

// Глобальные данные стора
import { getters, actions, methods } from '../../store/store';

export default {
	name: 'OsMember',
	components: {
		Menu,
		Header,
		Icons
	},
	computed: {
		...getters,
		calculateActiveTab() {
			// console.log('calculateActiveTab', this.$route.name);
			switch (this.$route.name) {
				case 'General':
					return 0;
				default:
					return false;
			}
		}
	},
	data: () => ({
		isMinzdravOgv: false,
		menuList: [
			{
				name: 'Главная',
				router: '/ogv/general',
				icon: 'home',
				access: true
			},
			{
				name: 'Результаты независимой оценки за 1-3 года',
				router: '/ogv/independent-evaluation-results',
				icon: 'union',
				access: true
			},
			{
				name: 'НОК текущего года',
				router: '/ogv/noc-current-year',
				icon: 'calendar',
				access: true,
				subItems: [
					{
						name: 'Перечень МО текущего года',
						router: '/ogv/noc-current-year/current-year',
						access: true
					},
					{
						name: 'Решения Общественного совета',
						router: '/ogv/noc-current-year/decisions-public-council',
						access: true
					},
					{
						name: 'Результаты анкетирования',
						router: '/ogv/noc-current-year/survey-results',
						access: true
					},
					{
						name: 'Результаты НОК текущего года',
						router: '/ogv/noc-current-year/noc-results-current-years',
						access: true
					}
				]
			},
			{
				name: 'Архив',
				router: '/ogv/archive',
				icon: 'driver',
				access: true
			},
			{
				name: 'Медицинские организации',
				router: '/ogv/medical-organizations',
				icon: 'pluse',
				access: true
			},
			{
				name: 'Общественный совет по НОК',
				router: '/ogv/public-council-noc',
				icon: 'task',
				access: true,
				subItems: [
					{
						name: 'Состав ОС по НОК',
						router: '/ogv/public-council-noc/compound',
						access: true
					},
					{
						name: 'Протоколы ОС по НОК',
						router: '/ogv/public-council-noc/protocols',
						access: true
					}
				]
			},
			{
				name: 'Блоки для сайта',
				router: '/ogv/blocks-site',
				icon: 'element',
				access: true
			},
			{
				name: 'Отзывы пациентов',
				router: '/ogv/patient-reviews',
				icon: 'message',
				access: true
			},
			{
				name: 'Аналитические отчеты',
				router: '/ogv/dinamical-filling',
				icon: 'diagram',
				access: true,
				subItems: [
					{
						name: 'Динамика заполнения анкет',
						router: '/ogv/dinamical-filling',
						access: true
					}
				]
			},
			{
				name: 'Список пользователей',
				router: '/ogv/administration',
				icon: 'setting',
				access: true
			}
		]
	}),
	methods: {
		...actions,
		...methods,
		goRoute(item) {
			if (item.subItems !== undefined && item.subItems.length > 0) {
				item = item.subItems[0];
			}

			if (item.router !== this.$route.path) {
				this.$router.push({ path: item.router, query: this.$route.query });
			}
		},
		tabIsActive(item, menu) {
			if (item.router === this.$route.path) {
				return true;
			}

			if (!menu && item.subItems !== undefined) {
				for (let i = 0; i < item.subItems.length; i += 1) {
					if (item.subItems[i].router === this.$route.path) {
						return true;
					}
				}
			}

			return false;
		}
	},
	created() {
		this.setCurrentRegion();
	},
	mounted() {
		this.isMinzdravOgv = this.userIsInRoles(['MZ_Employee', 'MZ_Observer']);

		if (!this.userIsInRoles(['Support', 'MZ_Employee', 'MZ_Observer'])) {
			let parameters = {
				type: 1,
				year: 2022,
				regionId: this.getAuthUser.regionId
			};

			this.getDecisionBlockStatus(parameters).then((result1) => {
				if (result1.data.settingValue === 'false') {
					this.menuList[2].subItems[3].access = false;
				} else {
					parameters.type = 2;

					this.getDecisionBlockStatus(parameters).then((result2) => {
						if (result2.data.settingValue === 'false') {
							this.menuList[2].subItems[3].access = false;
						}
					});
				}
			});
		}
	}
};
</script>

<style>
.clear-box {
	width: calc(100vw - 9rem - 48px);
}

.clear-box.open {
	width: calc(85vw - 9rem - 48px);
}

.active svg path {
	fill: #37f !important;
}
</style>
